/* Fixed header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 20px 0;
  font-size: 24px;
  font-weight: bold;
  z-index: 1000;
}

/* Add top margin to avoid overlap with header */
.text-feed {
  margin-top: 100px; /* Adjust based on your header height */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Job posting styling */
.text-item {
  width: 40vw; /* 40% of the viewport width */
  max-width: 600px; /* Prevent it from getting too wide */
  min-width: 300px; /* Prevent it from getting too narrow */
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  box-sizing: border-box;
}
