/* CSS for fixed header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Background color to make it stand out */
  text-align: center;
  padding: 20px 0; /* Padding to add space around the text */
  font-size: 24px; /* Font size for the text */
  font-weight: bold;
  z-index: 1000; /* Ensures it's on top of other content */
}

/* Additional margin-top to the feed to avoid overlap with the header */
.text-feed {
  margin-top: 80px; /* Adjust this value based on the header height */
}
